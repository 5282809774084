.login {
  padding: 1rem;
  text-align: left !important;
  min-height: 95vh;
  max-width: 600px;
  margin: 0 auto;
  color: #fff;
  padding-top: 6rem;

  label {
    font-size: .8rem;
    text-align: left;
    margin-top: 1rem;
  }
  
  input::placeholder,
  select::placeholder,
  textarea::placeholder {
    font-size: .9rem;
  }
  
  input,
  select,
  textarea {
    font-size: .9rem !important;
  }
}