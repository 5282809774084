.create-transaction {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.create-transaction h2 {
  text-align: center;
  color: #333;
}

.transaction-details,
.transaction-inputs,
.transaction-outputs {
  margin-bottom: 20px;
}

.transaction-inputs h3,
.transaction-outputs h3 {
  margin-bottom: 10px;
  color: #555;
}

.input,
.output,
.create-transaction .field {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.input label,
.output label,
.create-transaction .field label {
  margin-bottom: 5px;
  color: #666;
  text-align: left;
  font-size: .8rem;
}

.create-transaction input,
.create-transaction select {
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  background: transparent;
  border: 1px solid #ccc !important;
  color: #fff;
}

.create-transaction button {
  background-color: #007bff;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 10px;
}

.create-transaction button.red {
  background-color: #dc3545;
  width: 100%;
}

.create-transaction button.red:hover {
  background-color: #c82333;
}

.create-transaction button:hover {
  background-color: #0056b3;
}

.create-transaction button[type="submit"] {
  width: 100%;
  margin-top: 10px;
}

.transaction-fields {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

@media (max-width: 768px) {
  .transaction-fields {
      flex-direction: column;
  }
}

.transaction-inputs,
.transaction-outputs {
  flex: 1;
}
