footer {
    background: #0a0a0a;
    padding-top: 3rem;
}

.footer-columns, .footer-copyright {
	display: flex;
	justify-content: flex-start;
    text-align: left !important;
}

.column {
	flex: 1;
    font-weight: 600;
    display: flex;
    flex-direction: column;
}

.column a {
    margin-block-end: 1.2rem;
}

footer h3 {
    color: #fff;
    opacity: .3;
    margin-block-end: 1rem;
    margin-top: .8rem;
}

.check {
    margin-left: 10px;
    margin-top: .2rem;
}

.v-tooltip-placement-right {
    --v-tooltip-left: calc(100% + 6px); 
    --v-tooltip-top: calc(50% + 4px); 
    --v-tooltip-translate: translate(0%, -50%); 
    --v-tooltip-arrow-border-right-color: #fff; 
    --v-tooltip-arrow-top: calc(var(--v-tooltip-top) - 2px); 
    --v-tooltip-arrow-left: calc( var(--v-tooltip-left) - var(--v-tooltip-left-offset) - 5px);
}

.mobile-column {
    display: none;
    text-align: center !important;
}

.mobile-column a {
    margin-block-end: 1rem;
}

.socials img {
    display: inline-flex;
    margin: .5rem;
}

.socials {
    margin-bottom: 1rem;
}

.footer__row {
    align-items: center;
    display: grid;
    grid-template-columns: repeat(5,auto);
    justify-content: space-between;
    justify-items: stretch;
    padding-bottom: 5rem;
    margin-top: 2rem;
}

.footer__row__icon {
    height: 35px;
    width: auto;
}

.footer__row__logo {
    height: auto;
    width: 120px;
}

.txt-basic {
    font-size: 1.4rem;
    line-height: 110%;
    color: #fff;
    font-size: .9rem;
    text-align: left !important;
}

.footer__row__version {
    color: #1e1e1e;
    font-size: .6rem;
    text-align: left !important;
}

@media screen and (max-width: 768px) {
    .footer {
        margin-left: auto;
        margin-right: auto;
        width: 100%;
    }

    .column {
        display: none;
    }

    .mobile-column {
        display: flex;
        flex-direction: column;
        padding: 10px;
        font-weight: 600;
    }

    .footer-columns {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .footer__row {
        grid-row-gap: 30px;
        grid-column-gap: 15px;
        align-items: unset;
        grid-template-columns: 1fr 1fr;
        justify-content: center;
        justify-items: center;
        margin-top: 0rem;
        padding-bottom: 3rem;
    }

    .footer__row__icon {
        height: auto;
        width: 165px;
    }

    .footer__row__no-trackers {
        justify-self: end;
        order: 1;
    }

    .footer_img_js {
        justify-self: start;
        order: 2;
    }

    .footer__row__logo {
        display: none;
        order: 0;
    }

    .footer__row>.txt-basic {
        line-height: 160%;
    }

    .footer__row__copyright {
        opacity: .5;
        order: 3;
    }

    .footer__row__version {
        align-self: center;
        order: 4;
    }
}