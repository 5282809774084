.navbar-wrap {
    background: #0a0a0a;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    padding: .7rem;
}

.nav-cont {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nav-cont #searchbar--hp {
    width: 100%;
    max-width: 650px;
    margin-left: 1rem;
}

.nav-cont .searchbar__input {
    height: 40px !important;
    font-size: .9rem !important;
}