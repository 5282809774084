.hp-header__header {
    align-items: center;
    display: flex;
}

.hp-header__header>*+* {
    margin-left: 100px;
}

.hp-header__header__logo {
    height: auto;
    width: 200px;
}

.navbar-menu-wrap {
    align-items: center;
    display: flex;
}

.navbar-menu-wrap>#c_more, .navbar-menu-wrap>#c_more--scroll {
    display: none;
}

.navbar-menu-wrap>input[type=radio] {
    display: none;
}

.hp-header__header__menu .navbar-menu__item-wrap {
    color: #fff;
}

.navbar-menu__item-wrap {
    color: #fff;
    position: relative;
    font-size: 1rem;
}

.navbar-menu__item-wrap--text>.navbar-menu__item {
    align-items: center;
    border-radius: 5px;
    display: flex;
    justify-content: space-around;
    padding: 4px 10px;
}

.hp-header__header__menu .navbar-menu__item-wrap--explorers .navbar-menu__item__content-wrap {
    left: -50%;
    right: unset;
}

.navbar-menu__item__content-wrap {
    display: none;
    position: relative;
    z-index: 99;
}

.navbar-explorers-wrap {
    background-color: #131313;
    border-radius: 8px;
    padding: 25px 30px 30px;
}

.navbar-menu__item__content-wrap>div {
    position: relative;
    z-index: 2;
}

.navbar-menu__item {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
}

.language {
    font-size: 15px;
    margin-right: 10px;
}

.navbar-menu__item__settings-icon {
    height: 24px !important;
}

.hp-header__header>.navbar-menu__item-wrap--portfolio {
    margin-left: auto;
}

.navbar-portfolio {
    margin-right: 10px;
}

.hp-header__header .navbar-portfolio__nav-wrap {
    background-color: rgba(0,0,0,.498);
}

.navbar-portfolio__nav-wrap:hover {
    cursor: pointer;
    background: #2170ff;
}

.navbar-portfolio__nav-wrap {
    border-radius: 8px;
    font-family: Poppins,sans-serif;
    padding: 3px 10px;
    position: relative;
    transition: background-color .1s linear;
}

@media (max-width: 768px) {
    .hp-header__header {
        justify-content: space-between;
    }
    .hp-header__header__logo {
        height: auto;
        width: 155px;
    }

    .navbar-explorers-content-wrap {
        -ms-overflow-style: none;
        height: calc(100% - 75px);
        overflow-y: scroll;
        scrollbar-width: none;
        width: 100%;
    }

    .navbar-menu-wrap {
        display: none;
    }

    .navbar-menu__item-wrap {
        display: none;
    }
}

@media (max-width: 1281px) {
    .navbar-menu-wrap>input[type=radio]:checked+.navbar-menu__item-wrap>:not(.navbar-menu__item__content-wrap):not(label[for=c_more]):not(label[for=c_more--scroll]) {
        display: none;
    }

    .navbar-menu-wrap>input[type=radio]:checked+.navbar-menu__item-wrap>.navbar-menu__item__content-wrap {
        bottom: 0;
        display: block;
        left: 0;
        position: fixed;
        right: 0;
        top: 75px;
        z-index: 999;
    }

    .navbar-menu-wrap>#c_more--scroll:not(:checked)~.navbar-menu__item-wrap:not(.navbar-menu__item-wrap--lang)>.navbar-menu__item__content-wrap, .navbar-menu-wrap>#c_more:not(:checked)~.navbar-menu__item-wrap:not(.navbar-menu__item-wrap--lang)>.navbar-menu__item__content-wrap {
        display: none;
    }

    .navbar-explorers-wrap {
        border-radius: 0;
        height: 100%;
        padding: 0 10px 25px;
        width: 100%;
    }

    .navbar-menu__item__content-wrap>div {
        padding-bottom: 10px;
        position: fixed;
    }

    .hp-header__header__menu .navbar-menu__item-wrap>.navbar-menu__item__content-wrap>div {
        width: 100%;
    }

    .navbar-explorers-content-wrap {
        -ms-overflow-style: none;
        overflow-y: scroll;
        scrollbar-width: none;
        width: 100%;
    }
}