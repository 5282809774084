.transaction-list {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
}

.transaction-list h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #fff;
}

.transaction-list table {
    width: 100%;
    border-collapse: collapse;
}

.transaction-list th, .transaction-list td {
    padding: 8px;
    text-align: left;
}

.transaction-list th {
    background-color: #333;
    color: #fff;
    font-size: 1rem !important;
}

.transaction-list tr {
    color: #ccc;
    font-size: .8rem;
    line-height: 2.5rem;
}
.transaction-list tr:nth-child(even) {
    background-color: #333;
}

.transaction-list button {
    background-color: #ff4d4d;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
}

.transaction-list .edit {
    background-color: #217721;
}
.transaction-list .edit:hover {
    background-color: #187018;
}

.transaction-list button:hover {
    background-color: #ff3333;
}
