.hp-products-wrap {
    background: #0a0a0a;
    border-radius: 20px;
    margin-top: -21px;
    padding-bottom: 90px;
    padding-top: 90px;
}

.hp-products-content {
    color: #fff;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    max-width: 1280px;
}

.hp-products__grid {
    grid-column-gap: 15px;
    grid-row-gap: 15px;
    display: grid;
    grid-template-columns: repeat(3,auto);
    justify-items: stretch;
    margin-top: 40px;
}

.hp-banner-l-wrap {
    background: linear-gradient(115.27deg,#0c0c0c,#0f0f0f);
    border-radius: 20px;
    padding: 90px;
}

.hp-products__grid>.hp-banner-l-wrap:first-child {
    grid-column: span 2;
}

.hp-banner-l-content {
    align-items: center;
    color: #fff;
    display: flex;
    justify-content: space-between;
}

.hp-banner-l__text {
    grid-row-gap: 35px;
    display: grid;
    grid-template-columns: 100%;
    justify-items: start;
    max-width: 590px;
}

ul.blockchair-ul {
    font-family: Poppins,sans-serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 160%;
    margin: 0;
    padding-left: 25px;
    text-align: left;
    vertical-align: top;
}

ul.blockchair-ul li, ul.blockchair-ul ul li {
    list-style: outside;
    margin-bottom: 0;
}

.hp-banner-l__icon {
    height: 380px;
    padding: 10px 55px;
    width: auto;
}

.hp-banner-wrap {
    background: linear-gradient(115.27deg,#0c0c0c,#0f0f0f);
    border-radius: 20px;
    cursor: pointer;
    padding: 50px;
}

.hp-banner-content {
    grid-row-gap: 20px;
    align-items: stretch;
    color: #fff;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: max-content auto;
    height: 100%;
}

.hp-banner__icon-wrap {
    display: flex;
    justify-content: center;
}

.hp-banner__icon {
    height: 150px;
    width: 150px;
}

.h4 {
    text-align: left;
    font-size: 1.2rem;
    font-weight: 600;
}

.hp-banner-wrap:hover {
    background: #2170ff;
}

.button {
    align-items: center;
    border-radius: 8px;
    cursor: pointer;
    display: inline-flex;
    font-family: Inter,sans-serif;
    font-size: .9rem;
    font-weight: 600;
    height: 38px;
    justify-content: center;
    max-height: 38px;
    padding: 0 17px;
    position: relative;
    transition: none;
}

.button.primary {
    background-color: #2170ff;
    color: #fff;
}

@media screen and (max-width: 768px) {
    .hp-products-wrap {
        padding: 30px 0 15px;
    }

    .hp-products__grid {
        grid-row-gap: 10px;
        margin-left: 5px;
        margin-right: 5px;
    }

    .hp-banner-l-wrap {
        padding: 35px 30px 25px;
    }

    .hp-products__grid>.hp-banner-l-wrap:first-child {
        margin-top: -60px;
    }

    .hp-banner-l-content {
        align-items: unset;
        flex-direction: column;
        justify-content: unset;
    }

    .hp-banner-l__text {
        margin-top: 20px;
        order: 1;
    }

    .hp-banner-l__icon {
        height: 255px;
        order: 0;
        padding: 0;
        width: 255px;
    }

    .hp-banner-wrap {
        padding: 25px;
    }

    .hp-banner-content {
        grid-row-gap: 40px;
    }
}

@media screen and (max-width: 1023px) {
    .hp-products__grid {
        grid-template-columns: 100%;
    }
}

@media screen and (max-width: 1281px) {
    .hp-products-content {
        margin-left: 15px;
        margin-right: 15px;
        max-width: unset;
    }
}