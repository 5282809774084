.hp-news-wrap {
    padding-bottom: 140px;
    padding-top: 120px;
}

.hp-news-content {
    grid-row-gap: 30px;
    display: grid;
    grid-template-columns: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: 1280px;
}

.hp-news__news {
    grid-column-gap: 15px;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    margin-top: 15px;
}

.news-card-wrap {
    background: #131313;
    border: 1px solid #222;
    border-radius: 8px;
    color: #fff;
    cursor: pointer;
    display: grid;
    padding: 25px 30px 30px;
    position: relative;
    transition: box-shadow 1s linear;
}

.news-card-content {
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.news-card__header {
    color: #2170ff;
    text-align: left;
    font-size: 1.2rem;
}

.news-card__body {
    flex-grow: 1;
    margin-top: 15px;
    font-size: 1rem;
    line-height: 1.7rem;
    text-align: left;
}

.wb-ba {
    color: #555;
}

.news-card__footer {
    align-items: center;
    display: flex;
    justify-content: space-between;
    justify-self: flex-end;
    margin-top: 20px;
}

.hp-news__footer {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.hp-news__footer__more {
    align-items: center;
    color: #2170ff;
    cursor: pointer;
    display: flex;
}

.hp-news__footer__more__icon {
    align-items: center;
    display: flex;
}

.hp-news__footer__more__icon>svg {
    height: 16px;
    width: 16px;
}

.hp-news-wrap h3 {
    color: #fff;
    text-align: left;
}

.news-card-wrap:hover {
    /* Glow */
    box-shadow: 0 0 4px 1px #555;
}

.hp-news__footer__subscribe {
    display: flex;
    width: auto;
    align-items: center;
    gap: .3rem;
}

.hp-news__footer__subscribe__icon {
    margin-bottom: -.4rem;
}

@media screen and (max-width: 768px) {
    .hp-news-wrap {
        padding-bottom: 55px;
        padding-top: 55px;
    }

    .hp-news-content {
        grid-row-gap: 20px;
        margin-left: 0;
        margin-right: 0;
    }

    .hp-news-content>h3 {
        margin-left: 30px;
    }

    .hp-news__news {
        grid-row-gap: 10px;
        grid-template-columns: 100%;
        margin-left: 5px;
        margin-right: 5px;
    }

    .hp-news__footer {
        flex-direction: column;
        justify-content: space-around;
        margin-top: 0;
    }
}

@media screen and (max-width: 1281px) {
    .hp-news-content {
        margin-left: 15px;
        margin-right: 15px;
        max-width: unset;
    }
}