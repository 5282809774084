.bg-header-wrap {
    background-position: right 5% top 40%;
    background-repeat: no-repeat;
    padding-bottom: 140px;
    padding-top: 120px;
}

.hp-header__title-row {
    grid-column-gap: 50px;
    align-items: start;
    display: grid;
    grid-template-columns: 3fr 1fr;
    margin-bottom: 40px;
    margin-top: 80px;
    text-align: left;
}

.hp-header__title-row__caption {
    margin-top: 20px;
    font-size: 1.1rem;
}

.banner-promo-wrap {
    background: #0e0e0e;
    border: 1px solid #1e1e1e;
    border-radius: 8px;
    box-shadow: 0px 20px 24px -20px #0f12601a;
    display: block;
    padding: 20px;
    transition: background-color .1s linear;
}

.banner-promo-wrap.extension {
    padding: 20px;
}

.f-vert-dense {
    display: flex;
    flex-direction: column;
}

.f-hor-def {
    display: flex;
    align-items: center;
}

.banner-promo__icon, .banner-promo__icon>svg {
    height: 55px;
    width: 55px;
}

.banner-promo-wrap {
    color: #fff;
    font-size: .8rem;
}

.as-center {
    margin-left: 15px !important;
}

.safari-wrap {
    background: #333;
}

.searchbar__qr-wrap {
    --v-tooltip-left: auto; 
    --v-tooltip-right: -4px; 
    --v-tooltip-top: 0%; 
    --v-tooltip-translate: translate(0%, -100%); 
    --v-tooltip-arrow-border-top-color: var(--v-tooltip-background-color); 
    --v-tooltip-arrow-top: calc(var(--v-tooltip-top) - var(--v-tooltip-top-offset) + 10px);
    cursor: pointer;
    display: flex;
    margin-left: -1px;
    order: 3;
    padding: 6px 15px;
    align-items: stretch;
}

.searchbar__qr {
    opacity: .3;
}

.searchbar__qr:hover {
    opacity: 1;
}

.searchbar__label {
    align-items: center;
    background-color: #060606;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 8px;
    border-top-left-radius: 15px;
    border-top-right-radius: 8px;
    display: flex;
    width: 100%;
}

.searchbar__input {
    background-color: #0000;
    border-radius: 0;
    border-bottom-right-radius: var(--br-def);
    border-top-right-radius: var(--br-def);
    color: var(--c-txt-main);
    height: 54px;
    order: 1;
    padding-left: 10px;
}

.searchbar__glass {
    height: 17px;
    margin-left: 15px;
    min-height: 17px;
    min-width: 17px;
    opacity: .5;
    order: 0;
    pointer-events: none;
    transition: opacity .2s ease;
    width: 17px;
}

.searchbar__arrow {
    border-radius: 0 13px 13px 0;
    cursor: pointer;
    order: 4;
    padding: 0 14px 0 11px;
    background: #2170ff;
    border: none !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

input {
    font-size: 1rem;
    font-weight: 400;
    color: #fff !important;
    outline: none !important;
    box-shadow: none !important;
    border: none !important;
}

.chrome-wrap {
    background: #131313;
    border-radius: 8px;
    transition: background-color .1s linear,color .1s linear;
    margin-top: .3rem;
}

.banner-promo-wrap.extension .ext-button {
    border-radius: 5px;
    color: #2170ff;
    cursor: pointer;
    margin-top: 2px;
    min-height: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
    padding: 0rem .5rem;
}

.chrome-wrap:hover {
    background: #2170ff;
}

.chrome-wrap:hover .chrome-text {
    color: #fff !important;
}

.chrome-wrap:hover path {
    fill: #fff !important;

}

.chrome-text {
    display: flex;
    align-items: center;
    gap: 7px;
}

.hp-header__search-examples {
    align-items: center;
    display: grid;
    grid-auto-flow: column;
    justify-content: flex-start;
    margin-top: 15px;
    color: #fff;
    font-weight: 400;
    font-size: .8rem;
}

.hp-header__search-examples__item.__adress {
    margin-left: 20px;
}

.hp-header__search-examples__item.__block {
    margin-left: 20px;
}

.hp-header__search-examples__item.__transaction {
    margin-left: 15px;
}

.hp-header__search-examples__item.__embedded {
    margin-left: 14px;
}

.hp-header__search-examples__item {
    transition: color .25s ease;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

.hp-header__search-examples>*, .hp-header__search-examples__icon {
    align-items: center;
    display: flex;
}

@media (max-width: 768px) {
    .hp-header__title-row {
        grid-template-columns: 100%;
        margin-bottom: 20px;
        margin-top: 50px;
        padding-left: 25px;
        padding-right: 25px;
    }

    .bg-header-wrap {
        background-position: top 100px right -75px !important;
        background-size: 303px !important;
        padding-bottom: 50px !important;
        padding-top: 20px !important;
    }

    .hp-header__promo {
        display: none;
    }

    .hp-header__search-examples {
        display: none;
    }
}