
.transaction-wrap {
    text-align: left !important;
}
.page-transaction-areas {
    grid-column-gap: 20px;
    align-content: start;
    align-items: start;
    display: grid;
    grid-template-areas:
        "header header header"
        "hash mid right"
        "omni mid right"
        "table table table"
        "buttons buttons buttons"
        "news news news"
        "additional additional additional";
    grid-template-columns: 1fr 2fr 1fr !important;
}

.transaction__header {
    align-items: center;
    display: flex;
    grid-area: header !important;
    margin-bottom: 30px;
}

.breadcrumbs-wrap {
    align-items: center;
    align-self: stretch;
    display: flex;
    margin-top: 2rem;
}

.breadcrumbs__logo {
    height: 60px;
    margin-right: 15px;
    width: 60px;
}

.breadcrumbs__data {
    align-content: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.breadcrumbs__data>nav {
    align-items: center;
    display: flex;
}

.breadcrumbs__dot {
    margin-left: 8px;
    margin-right: 8px;
    opacity: .5;
}

.popup-local {
    color: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: 1rem;
}

.popup-local__checkbox {
    display: none;
}

.popup-local__overlay {
    background-color: #0003;
    bottom: 0;
    display: none;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 3;
}

.breadcrumbs__apiurl {
    border-radius: 5px;
    display: inline-block;
    font-size: 13px !important;
    line-height: 145%;
    margin-left: 5px;
    padding: 4px 8px 2px;
    vertical-align: middle;
    background: #18140a;
    border: 1px solid #1e1e1e;
}

.bread-link {
    opacity: .3;
    font-size: .8rem;
}

.bread-link:hover {
    opacity: 1;
    color: #2170ff;
}

.breadcrumps__title {
    display: flex;
    gap: .5rem;
}
.c-txt-quiet {
    color: grey;
}

.popup-local__arrow {
    background: #2170ff;
    content: "";
    display: none;
    height: 7px;
    position: absolute;
    width: 7px;
    z-index: 9;
}

.popup-local--bottom .popup-local__arrow {
    left: 50%;
    margin-left: 2px;
    top: 100%;
    transform: translateY(-50%) translateX(-50%) rotate(45deg);
}


.dot-grayblue-3px {
    background-color: #555;
    height: 3px;
    opacity: .5;
    vertical-align: middle;
    width: 3px;
}

.popup-local--bottom .popup-local__body {
    left: 50%;
    top: 100%;
    transform: translateX(-50%);
}

.popup-local__body {
    box-shadow: 0 30px 30px -30px #090b4759, 0 0 20px 0 #1f2a5226;
    cursor: default;
    display: none;
    min-width: 100px;
    overflow: hidden;
    padding: 30px 25px;
    position: absolute;
    z-index: 8;
}

.popup-local__close {
    border-radius: 50%;
    cursor: pointer;
    padding: 24px;
    position: absolute;
    right: -12px;
    top: -12px;
    transition: background-color .1s linear;
}

.breadcrumbs__apiurl__popup {
    width: 420px;
}

.content-wrap {
    background-color: #0e0e0e;
    border-radius: 8px;
    border: 1px solid #1e1e1e;
}

.transaction__hash-wrap__hat {
    position: relative;
}

.transaction__hash-wrap {
    grid-area: hash !important;
}

.transaction-costs__caption {
    color: #fff;
    opacity: .3;
}

.b-questionmark {
    margin-left: .5rem;
}

.transaction-costs__value {
    color: #fff !important;
}

.transaction-costs__values {
    display: flex;
    align-items: center;
}
.value-wrapper {
    color: #fff !important;
    font-size: .9rem !important;
}

.content-wrap-hat--short {
    background-color: #131313;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    height: auto;
    padding: 25px 25px 15px;
}

.content-wrap-body--short {
    padding: 20px 25px 25px;
}

.transaction-costs {
    grid-row-gap: 15px;
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: 100%;
}

.transaction-costs__dot {
    margin: 0 6px;
}

.hash-wrap {
    /* text-wrap: break-word !important; */
    position: relative !important;
    overflow-wrap: break-word !important;
    padding: 20px;
    max-width: 250px;
    padding-bottom: 0px;
}

.hash {
    color: #fff;
    font-size: 1.1rem;
    font-weight: 400;
    font-family: 'DM Mono', monospace;
    margin-top: .5rem;
    /* overflow-wrap: break-word !important; */
    max-width: 100%;
}

.transaction-io__cards-col__items .hash {
    font-size: .9rem !important;
    color: #2170ff;
}
.transaction-io__cards-col__items .hash:hover {
    text-decoration: underline;
}

.hash-exp__title {
    color: #fff;
    opacity: .3;
}

.hash__copy {
    margin-left: .5rem;
}

.tr-mid {
    grid-row-gap: 2px;
    display: grid;
    grid-area: mid;
    grid-template-columns: 100%;
    order: 1;
    max-width: 100% !important;
}

.tr-right {
    grid-row-gap: 10px;
    display: grid;
    grid-area: right;
    grid-auto-flow: row;
    grid-template-columns: 100%;
    max-width: 100% !important;
}

.time-wrap {
    margin-top: 1rem;
    opacity: .3;
    display: block !important;
}

.transaction-costs__dot {
    margin: 0 6px;
    vertical-align: middle;
}

.content-wrap {
    background-color: #0e0e0e;
    border-radius: 8px;
}

.content-wrap-hat {
    background-color: #131313;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    height: auto;
    padding: 25px;
}

.transaction-status__caption {
    color: #8191b5;
}

.transaction-status {
    align-items: center;
    display: flex;
    gap: 1rem;
}

.transaction-status__img {
    size: 76px;
    --first: #5bff21;
    --second: #293657;
    --third: #2170ff;
    --fourth: #2a4586;
    --dot-uno: #21dff3;
    --dot-dos: #ff9f00;
    --dot-tres: #ff5b81;
    --pen-dur: 15s;
    --dot-dur: 1.5s;
    --conf-dur: 1s;
}

.transaction-status__img #tx-status {
    align-items: center;
    display: flex;
    justify-content: center;
    position: relative;
    z-index: 0;
}

.transaction-status__img #tx-status .tx-status__check {
    align-items: first baseline;
    display: flex;
    margin-bottom: calc(48px/24);
    margin-left: calc(48px/50);
    opacity: 0;
    position: absolute;
    transform: rotate(45deg);
    transition: opacity .5s ease;
    z-index: 5;
}

.transaction-status__img #tx-status.__complete .tx-status__check {
    animation: tx-status-up .5s .3s;
    opacity: 1;
}

.transaction-status__img #tx-status.__complete .tx-status__check__item:first-child {
    animation: tx-status-splitW .3s .3s forwards;
    border-radius: calc(48px/30) 0 0 calc(48px/30);
    height: calc(48px/30);
    width: 0;
}

.transaction-status__img #tx-status.__complete .tx-status__check__item:nth-child(2) {
    animation: tx-status-splitH .3s .6s forwards;
    border-radius: calc(48px/30) calc(48px/30) 0 0;
    height: 0;
    margin-left: -1px;
    width: calc(48px/30);
}

.transaction-status__img #tx-status .tx-status__check__item {
    background-color: #fff;
    display: block;
}

.transaction-status__img #tx-status.__complete .tx-status__overflow, .transaction-status__img #tx-status.__pending .tx-status__overflow {
    background-image: linear-gradient(180deg, #5bff21 0, #2170ff 90%);
}

.circle {
    width: 72px;
    height: 72px;
    border-radius: 50%;
    background-image: linear-gradient(to bottom, #5bff21, #2170ff);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.loading-circle {
    width: 72px;
    height: 72px;
    border-radius: 50%;
    background-image: linear-gradient(180deg, #293657 0, #293657 90%);
    /* background-image: conic-gradient(#5bff21 0 0deg, transparent 180deg, #2170ff 360deg); */
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.checkmark {
    width: 2px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
    position: absolute;
}

.transaction-status__status {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 3px;
    margin-top: 5px;
}

.transaction-status__img #tx-status__progress {
    background-image: conic-gradient(#5bff21 0 0deg,#0000 10deg);
    border-radius: 50%;
    height: 72px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    transition: opacity .5s ease-in-out;
    width: 72px;
    z-index: 2;
}

.transaction-status__confirmations {
    color: #fff;
    display: flex;
    gap: .25rem;
    align-items: center;
    margin-top: .5rem;
}

.transaction-status__priority {
    color: #fff;
    font-size: .8rem;
    margin-top: .5rem;
}

.num-blocks {
    color: #2170ff;
    margin-left: .5rem;
}

.num-blocks:hover {
    text-decoration: underline;
}

.content-wrap-body--addinfo {
    background: #0e0e0e;
    border-radius: 8px;
    width: 100%;
}

.transaction-addinfo {
    display: flex;
    justify-content: space-between;

}

.transaction-addinfo__additional__item {
    padding: 10px;
}

.transaction-addinfo__expand-checkmark {
    height: 6.5px;
}

.transaction-addinfo__expand-container {
    display: flex;
    align-items: center;
    gap: .3rem;
    margin-left: .5rem;
}

.transaction-addinfo__expand-container:hover {
    cursor: pointer;
    color: #2170ff !important;
}

.transaction-addinfo__additional__item {
    display: flex;
    align-items: center;
    gap: .3rem;
}

.transaction-io__cards-wrap {
    display: flex;
}

.transaction-io__cards-col {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    border: 1px solid #1e1e1e;
    margin: .2rem;
    border-radius: 8px;
}

.transaction-io__total-counts__hat {
    padding: .5rem;
    font-weight: 300;
}

.transaction-io__total-counts__bubble {
    align-items: center;
    background-color: #fff;
    display: inline-flex;
    justify-content: space-around;
    min-height: 18px;
    min-width: 18px;
    margin-left: .3rem;
    border-radius: 50%;
    font-weight: 600;
    font-size: .7rem !important;
}

.transaction-io__cards-col__items {
    background: #131313;
    width: 100%;
}

.address-info-wrap {
    display: flex;
    align-items: center;
    gap: .3rem;
    color: #fff;
    font-size: .9rem;
    padding: 10px 20px;
}

.all-addresses-wrap {
    padding: .5rem;
    margin-top: .3rem;
}

.chart-side {
    border: 1px solid #1e1e1e;
    border-radius: 8px;
    padding: 20px;
}

.chart-side__header {
    display: flex;
    justify-content: space-between;
}

.price {
    color: #fff;
}

.last-month {
    font-size: .8rem;
}

.chart-side__chart {
    margin-top: 2rem;
}

.link-litecoin {
    display: flex;
    align-items: center;
    width: 90%;
    justify-content: space-between;
}

.misc-box {
    padding: 20px;
    background: #100d06;
    border-radius: 8px;
    border: 1px solid #1e1e1e;
}

.misc-box p {
    color: #fff;
    font-size: .8rem;
    opacity: .3;
}

.misc-box__items a {
    color: #2170ff !important;
    opacity: 1;
}

.misc-box__items a:hover {
    text-decoration: underline !important;
}

.margin-top-5 {
    margin-top: 10px;
}

.newswrap {
    background: #131313 !important;
    margin-top: 5rem;
}

.newswrap .hp-news-wrap {
    padding-top: 45px;
}

.real-hash {
    overflow-wrap: break-word !important;
    text-wrap: break-word !important;
    max-width: 310px;
}

@media screen and (max-width: 768px) {
    .transaction-status__status {
        align-self: start;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: start;
    }

    .transaction-status__img {
        --size: 48px;
        margin-top: 4px;
    }
    .transaction-status {
        align-items: start;
    }
    .content-wrap-body--short {
        padding: 15px;
    }
    .content-wrap-hat--short {
        padding: 10px 15px;
    }
    .page-transaction-areas {
        margin-top: 5px;
    }

    .transaction__header {
        align-items: start;
        flex-direction: column;
        margin-bottom: 5px;
    }

    .breadcrumbs-wrap {
        margin-bottom: 15px;
    }

    .breadcrumbs__logo {
        height: 60px;
        margin-right: 5px;
        width: 60px;
    }

    .breadcrumbs__data {
        margin-left: 10px;
    }  
    
    .breadcrumbs__data>nav {
        font-family: Inter,sans-serif;
        font-size: 1.3rem;
        font-weight: 400;
        line-height: 145%;
    }

    .breadcrumbs__dot {
        margin-left: 4px;
        margin-right: 4px;
    }

    .popup-local__body {
        left: 50%!important;
        position: fixed;
        top: 50%!important;
        transform: translate(-50%,-50%)!important;
        width: 100vw;
    }

    .breadcrumbs__apiurl__popup {
        width: 100%;
    }
}

@media screen and (max-width: 1023px) {
    .page-transaction-areas {
        grid-template-areas:
            "header"
            "hash"
            "mid"
            "omni"
            "pom"
            "promo"
            "additional"
            "buttons"
            "leftpromo"
            "table"
            "news" !important;
        grid-template-columns: 100% !important;
    }
}

@media screen and (max-width: 1281px) {
    .transaction__header {
        flex-direction: column-reverse;
    }
}

.tx-status__dots {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 50px; /* Adjust as needed */
    gap: .3rem;
}

.tx-status__dots__item {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: red; /* Default color, will be overridden */
    /* animation: jump 1.5s infinite cubic-bezier(0.6, -0.28, 0.735, 0.045); */
    animation: jump 1.5s infinite cubic-bezier(0.4, 0, 0.2, 1);
}

#dot1 {
    background-color: #21dff3;
    animation-delay: 0s;
}

#dot2 {
    background-color: #ff9f00;
    animation-delay: 0.5s;
}

#dot3 {
    background-color: #ff5b81;
    animation-delay: 1s;
}

@keyframes jump {
    0%, 100% {
        transform: translateY(-8px);
        opacity: 1;
    }
    50% {
        transform: translateY(8px); /* Adjust the height of the jump */
        opacity: 0;
    }
}
