* {
  padding: 0;
  margin: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


body {
  background: radial-gradient(50% 142.58% at 50% 50%,#121212 0%,#0b0b0b 74.48%);
}

.c-grayblue {
  color: #8191b5 !important;
}
.container-1280 {
  margin: auto;
  max-width: 1280px;
  position: relative;
}

@media (max-width: 1281px) {
  .container-1280 {
      margin-left: 15px;
      margin-right: 15px;
      max-width: unset;
  }
}

@media (max-width: 768px) {
  .container-1280 {
      margin-left: 10px;
      margin-right: 10px;
      max-width: unset;
  }
}

.p-relative {
  position: relative;
}

.h6, .h6m {
  font-size: 1.6rem;
  line-height: 140%;
}

.h6m, .h7 {
  font-weight: 500;
}

.fs-13 {
  font-size: 13px;
}

.fw-400, .regular {
  font-weight: 400;
}

.h1 {
  font-size: 2.3rem;
  line-height: 120%;
  font-weight: 600;
}

.w-100 {
  width: 100%;
}

.txt-basic {
  font-size: .9rem;
  line-height: 110%;
}

.bgc-accent {
  background-color: #2170ff !important; 
  border-color: #2170ff;
}

.bw-2 {
  border-width: 2px;
}

.br-15 {
  border-radius: 15px;
}

.bs-solid {
  border-style: solid;
}

.d-flex {
  display: flex;
}

a {
  text-decoration: none;
  color: #fff;
}

a:hover {
  color: #2170ff;
}

.fd-col {
  flex-direction: column;
}


.auth .form-group {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
}

.auth label {
  opacity: .3;
}

.auth input {
  width: 100%;
  padding: 1rem;
  border-radius: .5rem;
  margin-top: .3rem;
  color: #000 !important;
}

.auth .text-center {
  text-align: center;
}

.auth .mt-3 {
  margin-top: 1rem;
}

.text-danger, .invalid-feedback {
  color: #ff0000 !important;
  margin-top: .3rem;
}

.auth .button.primary {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.auth .button.primary:hover {
  background-color: #0056b3;
  transform: translateY(-2px);
}

.auth .button.primary:active {
  background-color: #004088;
  transform: translateY(1px);
}
