.hp-about-wrap {
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 120px;
    padding-top: 120px;
    text-align: left !important;
    color: #fff;
}

.hp-about-content {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    max-width: 1280px;
}

.hp-about__text {
    flex-basis: 50%;
    flex-grow: 0;
    flex-shrink: 1;
    line-height: 1.7rem !important;
    font-size: 1.05rem;
}

.hp-about__text>.h1 {
    margin-top: 20px;
}

.hp-about__text>.p {
    margin-top: 40px;
}

.hp-about__graphics {
    height: 540px;
    padding: 25px 5px;
    width: 540px;
}

@media screen and (max-width: 768px) {
    .hp-about-wrap {
        background-position: bottom;
        background-size: contain;
        padding-bottom: 80px;
        padding-top: 60px;
    }

    .hp-about__text {
        flex: unset;
    }

    .hp-about-content {
        flex-direction: column;
        justify-content: unset;
    }

    .hp-about__text>.h1 {
        font-family: Poppins,sans-serif;
        font-size: 2.3rem;
        font-weight: 600;
        line-height: 145%;
        margin-top: 0;
    }

    .hp-about__text>.p {
        margin-top: 20px;
    }

    .hp-about__graphics {
        height: 276px;
        margin-top: 20px;
        width: 276px;
    }
}

@media screen and (max-width: 1281px) {
    .hp-about-content {
        margin-left: 15px;
        margin-right: 10px;
    }
}