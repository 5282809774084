.admin-navbar {
    padding-top: 20px;
}

.admin-navbar ul {
    list-style-type: none;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.admin-navbar li {
    margin: 0 10px;
    padding: 12px;
}

.admin-navbar a {
    text-decoration: none;
    color: #ccc;
    font-weight: bold;
}

.admin-navbar a:hover {
    color: #007bff;
}
