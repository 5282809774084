.title {
    text-align: left;
    color: #fff;
    font-size: 1.8rem;
    padding-top: 5rem;
    margin-bottom: 2rem;
}

.hp-explore__cards-wrap {
    margin-top: 40px;
}

.hp-explore-wrap {
    background-color: #0a0a0a;
}

.explore-card__header__logo {
    height: 55px;
    width: 55px;
}

.hp-explore__cards-content {
    grid-row-gap: 15px;
    grid-column-gap: 15px;
    align-items: start;
    display: grid;
    grid-template-columns: repeat(4,1fr);
    justify-items: stretch;
}

.explore-card-wrap {
    border-radius: 20px;
    color: #fff;
    cursor: pointer;
    display: block;
    padding: 25px;
    text-decoration: none !important;
    background: linear-gradient(115.27deg,#0c0c0c,#0f0f0f);
}

.explore-card-wrap:hover {
    background: linear-gradient(107.34deg,#4409ec,#0e5dec);
    box-shadow: 0 4px 24px -10px #0006;

    .explore-card-info-row__title {
        opacity: 1;
    }
}

.explore-card-head {
    display: flex;
    align-items: center;
}

.explore-card-head__title {
    margin-left: 15px;
    text-align: left !important;
}

.explore-card-head__title h5 {
    font-size: 1.1rem;
    font-weight: 600;
    margin: 0;
}
.explore-card-price {
    font-size: .9rem;
    margin: 0;
    letter-spacing: 1px;
}

.explore-card-sm__logo {
    height: 40px;
    width: 40px;
}

.explore-card-info-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: .5rem;
}

.explore-card-info-row__title {
    opacity: .3;
    font-size: .8rem;
}

.explore-card-info-row__value {
    font-size: .8rem;
}

.price-increase {
    display: flex;
    align-items: center;
    margin-top: .3rem;
}

.price-change {
    display: flex;
    margin-left: 10px;
    align-items: center;
    font-size: .9rem !important;
}

.price-change__value {
    margin-left: 5px;
}

.explore-card-sm-wrap {
    background: linear-gradient(115.27deg,#0c0c0c,#0f0f0f);
    border-radius: 20px;
    color: #fff;
    cursor: pointer;
    padding: 15px 25px;
    text-decoration: none !important;
}

.explore-card-body {
    margin-top: .8rem;
}

.price-change__arrow--down {
    transform: rotate(180deg);
    margin-top: .1rem;
}

@media screen and (max-width: 768px) {
    .hp-explore__cards-content {
        grid-row-gap: 5px;
    }

    .explore-card-wrap {
        padding: 15px;
    }
}

@media screen and (max-width: 1023px) {
    .hp-explore__cards-content {
        grid-template-columns: 100% !important;
    }
}

@media screen and (max-width: 1281px) {

    .hp-explore__cards-content {
        grid-template-columns: repeat(3,1fr);
    }

    .explore-card-wrap:nth-child(9) {
        grid-column-start: 1 !important;
    }

}