.hp-products-wrap h2 {
    text-align: left;
    font-size: 2rem;
}

.hp-banner__body {
    text-align: left;
}

.hp-banner__body p {
    line-height: 1.7rem;
}