.privacy-wrap {
    background-position: 50%;
    background-size: cover;
    padding-bottom: 120px;
    padding-top: 120px;
}

.privacy-wrap .h1 {
    color: #fff;
    text-align: left;
    margin-bottom: 1rem;
}

.privacy-wrap .h3 {
    color: #fff;
    text-align: left;
    margin-bottom: 1rem;
}

.privacy-wrap .p {
    color: #fff;
    text-align: left;
    margin-bottom: 1rem;
    line-height: 1.7rem;
    font-size: 1.05rem;
}

.privacy-content {
    align-items: flex-end;
    display: flex;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
    max-width: 1280px;
}

.privacy__body {
    grid-row-gap: 40px;
    display: grid;
    grid-template-columns: 100%;
    max-width: 640px;
}

.privacy__body__urls__caption, .privacy__body__urls__onion {
    align-items: center;
    display: flex;
}

.privacy__body__urls__onion>svg {
    height: 20px;
    width: 16px;
}

.privacy__body__urls__buttons {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
}

.privacy__body__urls__buttons button.button {
    --svg-opacity: 0.25;
    align-items: center;
    display: inline-flex;
}

.privacy__body__protocols {
    align-items: center;
    color: #2170ff;
    cursor: pointer;
    display: flex;
}

.privacy__icon {
    height: auto;
    margin-left: 5px;
    width: 500px;
}

.privacy__body__urls__buttons button.button {
    --svg-opacity: 0.25;
    align-items: center;
    display: inline-flex;
}

@media screen and (max-width: 768px) {
    .privacy-content {
        align-items: unset;
        flex-direction: column;
        justify-content: unset;
        margin-top: 0;
    }

    .privacy__body {
        grid-row-gap: 20px;
        margin-top: 20px;
        max-width: unset;
        order: 1;
        text-align: left;
    }

    .privacy__body__urls__buttons {
        grid-column-gap: 10px;
        display: grid;
        grid-template-columns: repeat(2,1fr);
        width: 100%;
    }

    .privacy__body__urls__buttons button.button {
        justify-content: center;
        text-align: center;
        width: 100%;
    }

    .privacy__icon {
        align-self: center;
        height: 256px;
        order: 0;
        width: auto;
    }

    .privacy__body__urls__buttons button.button {
        justify-content: center;
        text-align: center;
        width: 100%;
    }
}

@media screen and (max-width: 1281px) {
    .privacy-content {
        margin-left: 15px;
        margin-right: 15px;
    }
}