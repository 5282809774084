@font-face {
    font-family: 'Inter';
    src: url(./inter-regular.woff2) format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
    @font-face {
    font-family: 'Inter';
    src: url(./inter-medium.woff2);
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
    @font-face {
    font-family: 'Inter';
    src: url(./inter-semibold.woff2) format('woff2');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
    @font-face {
    font-family: 'Poppins';
    src: url(./poppins-cyr-regular.woff2) format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
    @font-face {
    font-family: 'Poppins';
    src: url(./poppins-cyr-medium.woff2) format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
    @font-face {
    font-family: 'Poppins';
    src: url(./poppins-cyr-semibold.woff2) format('woff2');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
    @font-face {
    font-family: 'DM Mono';
    src: url(./dm-mono-medium.woff2) format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

h1, h2, h3, h4, h5, h6, .donut-list__item__title, .h1, .h2, .h3, .h4, .h5, .h6, .h6m  {
    font-family: Poppins, sans-serif !important;
}

p {
    font-family: Inter, sans-serif !important;
}

.font-mono {
    font-family: DM Mono,monospace !important;
    line-height: 100%;
}