.layer2wrap {
    background-color: #0a0a0a;
    padding-top: 90px;
    color: #414141 !important;
    padding-bottom: 90px;
}

.line {
    background-color: #414141; 
    width: 100%; 
    height: 1px;
}

.h6m {
    font-size: 1rem;
    width: 11rem;
    text-align: left !important;
}

.line-wrap {
    display: flex;
    align-items: center;
}

.token-wrapper {
    display: flex;
    flex-direction: row;
    margin-top: 5rem;
}

.tokens {
    width: 50%;
    color: #fff;
    display: flex;
    flex-direction: column;
}

.hp-layer2__tokens__item {
    align-items: center;
    border-radius: 8px;
    color: #fff;
    cursor: pointer;
    display: flex;
    padding: 15px 25px;
    text-decoration: none !important;
}

.hp-layer2__tokens__item__logo {
    align-items: center;
    display: flex;
    height: 40px;
    justify-content: center;
    width: 40px;
}

.hp-layer2-wrap {
    grid-column-gap: 40px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 70px;
}

.hp-layer2__tokens__item__data {
    grid-row-gap: 5px;
    display: grid;
    grid-template-columns: 100%;
    margin-left: 15px;
    font-size: 1rem;
}

.c-txt-quiet {
    color: grey;
    font-size: .9rem !important;
}

.h6 {
    font-size: 1rem;
    font-weight: 600;
    text-align: left;
    line-height: 1rem !important;
}

.txt-data {
    display: flex !important;
    flex-direction: row !important;
    text-align: left;
    align-items: center;
}

.price-wrap {
    letter-spacing: 1px;
    font-size: .9rem !important;
    line-height: .9rem !important;
    margin-left: 5px;
}

.hp-layer2__tokens__item:hover {
    background-color: #1a1a1a;
    color: #2170ff;
}

.token-link {
    font-weight: 600;
    margin-top: auto;
    padding-top: 1.5rem;
}

.token-arrow {
    margin-left: 10px;
}

.display-block {
    display: block;
}

.explore-layers {
    display: grid; grid-template-columns: auto max-content; column-gap: 10px; align-items: center;
}

.hp-explore__layer2-explorers-wrap {
    grid-column-gap: 30px;
    display: grid;
    grid-template-columns: repeat(4,1fr);
    margin-top: 5rem;
}

.hp-explore__layer2-explorers__item {
    color: #fff;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-left: auto;
    margin-right: auto;
}

.hp-explore__layer2-explorers__img {
    border-radius: 50%;
    height: 60px;
    margin-bottom: 15px;
    padding: 27px;
    width: 60px;
    margin-left: auto !important;
    margin-right: auto !important;
}

.hp-explore__eth-header__title {
    margin-left: auto;
    margin-right: auto;
}

.ta-center {
    font-weight: 600;
    font-size: 1rem;
}

.hp-explore__banners {
    grid-column-gap: 40px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: start;
    justify-items: stretch;
    margin-top: 100px;
}

.hp-explore-banner-wrap {
    cursor: pointer;
}

.hp-explore-banner-content {
    grid-column-gap: 25px;
    display: grid;
    grid-template-columns: 110px auto;
    text-align: left;
}

.hp-explore-banner__img {
    align-self: center;
    height: 110px;
    width: 110px;
}

.h3 {
    font-size: 1.5rem;
}

.text-muted {
    opacity: .3;
    line-height: 1.5rem;
}


@media screen and (max-width: 1023px) {
    .hp-layer2__tokens-wrap {
        grid-template-columns: 100%;
    }

    .hp-explore__layer2-explorers-wrap {
        grid-column-gap: 10px;
        grid-row-gap: 10px;
        font-family: Poppins,sans-serif;
        font-size: 1.6rem;
        font-weight: 500;
        grid-template-columns: repeat(2,1fr);
        line-height: 140%;
        margin-top: 80px;
    }

    .hp-explore__layer2-explorers__img {
        border-radius: 8px;
        height: 60px;
        margin-bottom: 5px;
        padding: 0;
        width: 60px;
    }

    .hp-explore__banners {
        grid-row-gap: 20px;
        grid-template-columns: 100%;
        margin-top: 40px;
    }

    .hp-explore-banner-content {
        grid-column-gap: 15px;
        grid-template-columns: 80px auto;
    }

    .hp-explore-banner__img {
        height: 80px;
        width: 80px;
    }
}