.register {
  padding: 1rem;
  text-align: left !important;
  min-height: 95vh;
  max-width: 600px;
  margin: 0 auto;
  color: #fff;

  .title {
    text-align: center;
  }
}



.button-wrap {
    margin-top: 1.3rem;
    display: flex;
    justify-content: center;
}