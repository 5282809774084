.ips-list {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    color: #fff;
}

.ips-list h2 {
    text-align: center;
    margin-bottom: 20px;
}

.ips-list table {
    width: 100%;
    border-collapse: collapse;
}

.ips-list th, .ips-list td {
    padding: 8px;
    text-align: left;
    color: #ccc; /* Light grey text */
}

.ips-list th {
    background-color: #333; /* Darker header background */
    font-size: 1rem;
}

.ips-list tr:nth-child(even) {
    background-color: #333; /* Row stripe color */
}

.ips-list tr:hover {
    background-color: #444; /* Hover state for rows */
}

.ips-list button {
    background-color: #ff4d4d; /* Red buttons */
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.ips-list button:hover {
    background-color: #ff3333; /* Darker red on hover */
}

.pagination {
    text-align: center;
    margin-top: 20px;
}

.pagination span {
    margin: 0 10px;
}

/* Responsive styling */
@media (max-width: 600px) {
    .ips-list {
        width: 90%;
        padding: 10px;
    }

    .ips-list th, .ips-list td {
        padding: 8px;
    }

    .ips-list button {
        padding: 6px 12px;
        font-size: 0.8rem;
    }
}
